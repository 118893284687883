<template>
  <v-dialog :value="show" max-width="600" @input="$emit('close', 'refund')">
    <v-card>
      <v-container>
        <v-card-title>
          {{ $t('pages.common.refund_transaction') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('components.refund_transaction_modal.transactionId') }}:
          <b>
            {{ form.transactionId }}
          </b>
          <br />
          {{ $t('components.refund_transaction_modal.orderTotal') }}:
          <b> {{ form.orderTotal }} {{ form.currencyCode }} </b>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="$emit('close', 'refund')">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn color="primary" @click="$emit('refund')">
            {{ $t('common.refund') }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RefundTransactionModal',
  props: ['form', 'show']
}
</script>
